// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'ro',
    brand: 'plk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: 'd94b67faff85092576f39932ddd2c88dd83af0cf',
    releaseTagRef: '19e8cf9',
    releaseTimestamp: '1731396021',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: 'live_WUWGG77E4BBZLHAZQGUC6XBGFYVQT355',
      environment: 'live',
      urlPrefix: 'https://checkoutshopper-live.adyen.com',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '286e162c7a290e70185c97ccdd337b26',
      branch: '',
      braze: '7b91804d-c266-46de-bc1b-b2f8ed8623ef',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyBa0keJf7i3mcVDhvjd8Rsl69xYmS56kpY',
      tomtom: '7cRU4S5h2r8APzvquAsxatRxGWsy7wlP',
      launchDarkly: '66435a394bfcff0f83415b47',
      launchDarklyMobileKey: 'mob-504bbaef-d295-439a-8f1b-0e570c4df553',
      mParticle: 'eu1-8ee153021ac28c4d858db87a674e516b',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: 'merchant.ro.popeyes',
      migrationMerchantId: '',
      paymentsNetworks: ['MasterCard','Visa'],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-plk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-plk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '30hr4b7kbgbtvbv77ian7h0m9n',
      userPoolId: 'eu-central-1_z6T3UVf4Z',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'adyen',
      marketPaymentsGatewayMerchantId: 'RexConcepts',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-W9PM73X9',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.fra-01.braze.eu/api/v3',
      firstData: 'https://api-int.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: 'C0002',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: '',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '0190de5f-7e2a-7821-996e-15ea9dccda5f',
        },
      },
    }
  };